<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">
      <v-data-table
        :headers="headers"
        :items="expensesRecord"
        hide-default-footer
        class="mx-4"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="8"
        style="border:2px solid rgba(39,135,255,0.3);"
      ></v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          max-width="500"
          outlined
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "单号",
        align: "start",
        sortable: false,
        value: "number",
        class: "head-style title"
      },
      { text: "操作人", value: "username", class: "head-style title" },
      { text: "操作时间", value: "time", class: "head-style title" },
      { text: "船名", value: "shipname", class: "head-style title" },
      { text: "作业航次", value: "voyage", class: "head-style title" },
      { text: "扣费金额", value: "servicemoney", class: "head-style title" },
      { text: "公司余额", value: "balance", class: "head-style title" }
    ],
    // 充值记录
    expensesRecord: []
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      //获取用户基础信息
      const infonList = JSON.parse(localStorage.getItem("info"));
      // 获取充值记录
      this.$server.expensesRecord().then(data => {
        this.expensesRecord = data.data;
        for (const i in this.expensesRecord) {
          this.expensesRecord[i].time = this.$moment
            .unix(this.expensesRecord[i].time)
            .format("YYYY-MM-DD HH:mm:ss");
        }
      });
    }
  }
};
</script>

<style>
</style>